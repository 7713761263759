import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, makeStyles, Theme, createStyles } from '@material-ui/core'
import IndexLayout from './IndexLayout'

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    markdownRemark: {
      html: string
      id: string
      frontmatter: {
        name: string
        bio: string
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mdxSection: {
      textAlign: 'left'
    }
  })
)

const PageTemplate: React.SFC<PageTemplateProps> = ({ data }) => {
  const classes = useStyles()
  return (
    <IndexLayout>
      <Container maxWidth="md">
        <div className={classes.mdxSection} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      </Container>
    </IndexLayout>
  )
}

export default PageTemplate

export const query = graphql`
  query TeamTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        bio
      }
    }
  }
`
